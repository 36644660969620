// Dependencies
@import '../../node_modules/bootstrap/dist/css/bootstrap.css';
@import '../../node_modules/quill/dist/quill.snow.css';
@import '../../node_modules/@fortawesome/fontawesome-free/css/all.css';

// Global resets
* {
  box-sizing: border-box;
  margin: 0;
}

html,
body {
  height: 100%;
}

// Feature specific css
@import './abbr.scss';
@import './hoveringMenu.scss';
@import './range.scss';
@import './searchtable.scss';
@import './footer.scss';
