$footerHeight: 70px;

#body {
  min-height: calc(100% - #{$footerHeight});
}

#body:after {
  content: '';
  display: block;
}

#footer,
#body:after {
  height: $footerHeight;
}

#footer {
  background: #313437;
}

.footer-list {
  display: inline-block;
  color: white;
}

.footer-list:hover {
  color: #b1953a;
}

.footer-lis:after {
  content: '\007c';
}

.footer-list > a {
  color: white;
}

.footer-list > a:hover {
  color: #b1953a;
}
