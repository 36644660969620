input[type='range'] {
  -webkit-appearance: none;
  width: 100%;
  margin: 9.8px 0;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 11.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #b1953a;
  border-radius: 8.5px;
  border: 0px solid #b19500;
}
input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 1.9px solid #adadad;
  height: 31px;
  width: 31px;
  border-radius: 31px;
  background: #260859;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -9.8px;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: #c1a443;
}
input[type='range']::-moz-range-track {
  width: 100%;
  height: 11.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #b1953a;
  border-radius: 8.5px;
  border: 0px solid #b19500;
}
input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 1.9px solid #adadad;
  height: 31px;
  width: 31px;
  border-radius: 31px;
  background: #260859;
  cursor: pointer;
}
input[type='range']::-ms-track {
  width: 100%;
  height: 11.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  background: #9e8534;
  border: 0px solid #b19500;
  border-radius: 17px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type='range']::-ms-fill-upper {
  background: #b1953a;
  border: 0px solid #b19500;
  border-radius: 17px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type='range']::-ms-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 1.9px solid #adadad;
  height: 31px;
  width: 31px;
  border-radius: 31px;
  background: #260859;
  cursor: pointer;
  height: 11.4px;
}
input[type='range']:focus::-ms-fill-lower {
  background: #b1953a;
}
input[type='range']:focus::-ms-fill-upper {
  background: #c1a443;
}

/* // Range Label */
.range-slider__value {
  display: inline-block;
  position: relative;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  background: #adafb2;
  padding: 5px 10px;
  margin-bottom: 8px;
  color: white;
}
